import LevelOne from '@/assets/icons/levels/1.svg?raw';
import LevelTwo from '@/assets/icons/levels/2.svg?raw';
import LevelThree from '@/assets/icons/levels/3.svg?raw';
import LevelFour from '@/assets/icons/levels/4.svg?raw';
import LevelFive from '@/assets/icons/levels/5.svg?raw';
import LevelSix from '@/assets/icons/levels/6.svg?raw';
import LevelSeven from '@/assets/icons/levels/7.svg?raw';
import LevelEight from '@/assets/icons/levels/8.svg?raw';
import LevelNine from '@/assets/icons/levels/9.svg?raw';
import { createImageElementFromSVG } from '@/utils/helpers';

const levelIconsMap: Record<string, string> = {
  1: LevelOne,
  2: LevelTwo,
  3: LevelThree,
  4: LevelFour,
  5: LevelFive,
  6: LevelSix,
  7: LevelSeven,
  8: LevelEight
};

export default async function (
  level: number,
  width = 14,
  height = 14
): Promise<HTMLImageElement> {
  const svgString = level >= 9 ? LevelNine : levelIconsMap[level];
  return await createImageElementFromSVG(svgString, width, height);
}
